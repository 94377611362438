<template>
  <div class="text-center">
    <ClientHeader :client="client" />
    <v-row>
      <v-col v-if="loading"  lass="d-flex justify-center align-center mt-8">
        <v-progress-circular
            :size="70"
            :width="7"
            indeterminate
        ></v-progress-circular>
      </v-col>
      <v-col cols="12">
        <h2 v-if="!calcs.length && !showClalcButon && !loading" class="mt-5">There are no calculations for the current client yet.</h2>
        <v-btn v-if="showClalcButon && !loading" color="primary" @click="generateCalc">Generate Calculation</v-btn>
        <v-hover v-for="item in calcs"  class="mb-3" v-slot="{ hover }">
        <v-card :elevation="hover ? 8 : 4"  class="pa-6 d-flex flex-row justify-space-between align-center" >
          <div class="d-flex justify-start align-start flex-column">
            <h4 class="mb-3">{{item.original_file_name}}</h4>
            <h4>{{item.created}}</h4>
          </div>
          <v-chip color="green">{{ item.type }}</v-chip>
          <a :download="item.original_file_name"
             :href="item.url"
             v-show="item.id"
             target="_blank"
             class="ml-2"
             type="button"
             title="Download Calculation"
             id="downloadsBtn"
          >
            <v-icon size="35">
              mdi-download
            </v-icon>
          </a>

        </v-card>
        </v-hover>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import ClientsService from "@/services/clients.service";
import ClientService from "@/services/clients.service";
import ClientHeader from "@/components/Client/ClientHeader.vue";

export default {
  name: "Downloads",
  components: {
    ClientHeader,
  },
  data: () => ({
    loading: false,
    client: null,
    clientId: "",
    calcs: [],
    showClalcButon:false,
  }),
  methods: {
    getCurrentClient(){
      ClientService.getOneClient(this.clientId).then(res=>{
        this.client = res
      })
    },
    downloadURI(dataurl, filename) {
      const link = document.createElement("a");
      link.href = dataurl;
      link.download = filename;
      link.click();
    },
    getPackages(){
      ClientService.getAllPackages(this.clientId).then(res=>{
         if(res.length){
           this.showClalcButon=true
         }
      })

    },
    generateCalc() {
      this.loading=true
      ClientsService.generateCalculation(this.clientId).then(res=>{
        this.getCalculations()
        this.calcs = res
        this.showClalcButon=false
        this.loading=false
        this.$func.popMessage("Calculation generated successfully", "success")
      }).finally(()=>{
        this.loading=false
      })
    },
    async getCalculations() {
      await ClientsService.downloadCalculations(this.clientId)
        .then((res) => {
          // this.downloadURI(res.url, res.original_file_name);
          this.calcs=res
          this.loading = false;
          if (!res.length) {
            this.getPackages()
          }
          // window.open(res.url);
          // window.focus()
        })
        .catch(() => (this.loading = false));
    },
  },
  created() {
    this.loading = true;
    this.clientId = this.$route.params.clientId
    this.getCalculations()
    this.getCurrentClient()
  },
  // props: {
  //   clientProp: {
  //     type: Object,
  //   },
  // },
};
</script>

<style scoped></style>
